body { background-color: var(--clr-4037); }
body { color: var(--clr-4038); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Lato';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-4038);
font-family: 'Lato';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 14;
font-family: 'Lato';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Lato';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Lato';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Lato';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-4039);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-4039);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-4039);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-4039);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: #ffffff;
&:hover { color: #ffffff;}
 }
.MEC3 li {color: #ffffff;}
cite.MEC3{
color: #ffffff;
}
/* Shade 1:4 */
.MES4 {
background-color: var(--clr-4040);
 }
/* Shade 1:5 */
.MES5 {
background-color: var(--clr-4040);
 }
.MES5 {
background-color: var(--clr-4040);
 }
/* topbar placeholder:6 */
.MES6 {
background-color: #ffffff;
padding: 15px 0 0 0;

 }
.MES6 {
background-color: #ffffff;
padding: 15px 0 0 0;

 }
/* Menu Bar:7 */
.MES7 {
background-color: #ffffff;
padding: 0 0 15px 0;

 }
.MES7 {
background-color: #ffffff;
padding: 0 0 15px 0;

h1.MEC7 { font-size: 26px; }
h1.MEC7 { @media #{$medium-up} { font-size: 40px; }; }
h2.MEC7 { font-size: 22.88px; }
h2.MEC7 { @media #{$medium-up} { font-size: 35px; }; }
h3.MEC7 { font-size: 18.72px; }
h3.MEC7 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC7 { font-size: 18.72px; }
h4.MEC7 { @media #{$medium-up} { font-size: 25px; }; }
h5.MEC7 { font-size: 17.68px; }
h5.MEC7 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC7 { font-size: 16.64px; }
h6.MEC7 { @media #{$medium-up} { font-size: 18px; }; }
 }
/* :8 */
nav.me-Menu.MES8 {
.menu-item.MEC8{ &:hover {background-color: var(--clr-4040);}
}
& .menu-item.MEC8, & .menu-item.MEC8 > div.MEC8{ & > a.MEC8{color: var(--clr-4038);
text-transform: uppercase;
}
 &:hover > a.MEC8{color: var(--clr-4038);
}
 }
&.horizontal > .menu-item.MEC8 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC8 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC8 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC8 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Content:9 */
.MES9 {
background-color: #ffffff;
color: var(--clr-4038);
padding: 20px 15px;

@media #{$medium-up} {
padding: 20px 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES9 {
background-color: #ffffff;
color: var(--clr-4038);
padding: 20px 15px;

@media #{$medium-up} {
padding: 20px 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-4039);
 }
 }
a.MEC9 { color: var(--clr-4039);
&:hover { color: var(--clr-4039);}
 }
.MEC9 li {color: var(--clr-4038-flat);}
cite.MEC9{
color: var(--clr-4038);
}
/* Dark:10 */
.MES10 {
background-color: var(--clr-4041);
 }
/* Dark:11 */
.MES11 {
background-color: var(--clr-4041);
color: var(--clr-4037);
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES11 {
background-color: var(--clr-4041);
color: var(--clr-4037);
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC11{
color: var(--clr-4037);
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* Slider Wrap:12 */
.MES12 {
color: #ffffff;
 }
.MES12 {
color: #ffffff;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: #ffffff;
 }
h1.MEC12 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC12 { @media #{$large-up} { font-size: 80px; }; }
h2.MEC12 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC12 { @media #{$large-up} { font-size: 70px; }; }
h3.MEC12 { @media #{$medium-up} { font-size: 36px; }; }
h3.MEC12 { @media #{$large-up} { font-size: 60px; }; }
h4.MEC12 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC12 { @media #{$large-up} { font-size: 50px; }; }
h5.MEC12 { @media #{$medium-up} { font-size: 24px; }; }
h5.MEC12 { @media #{$large-up} { font-size: 40px; }; }
h6.MEC12 { @media #{$medium-up} { font-size: 21.6px; }; }
h6.MEC12 { @media #{$large-up} { font-size: 36px; }; }
 }
cite.MEC12{
color: #ffffff;
}
/* Home Slider:13 */
.MES13 {
& .slider-arrow {color: var(--clr-4040);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: #ffffff;
} }
/* home block:14 */
.MES14 {
background-color: #ffffff;
color: var(--clr-4038);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4040);
 }
.MES14 {
background-color: #ffffff;
color: var(--clr-4038);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-4040);
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: var(--clr-4038);
 }
h1.MEC14 { font-size: 15px; }
h1.MEC14 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC14 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC14 { font-size: 13.2px; }
h2.MEC14 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC14 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC14 { font-size: 10.8px; }
h3.MEC14 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC14 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC14 { font-size: 10.8px; }
h4.MEC14 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC14 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC14 { font-size: 10.2px; }
h5.MEC14 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC14 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC14 { font-size: 9.6px; }
h6.MEC14 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC14 { @media #{$large-up} { font-size: 18px; }; }
 }
.MEC14 li {color: var(--clr-4038-flat);}
cite.MEC14{
color: var(--clr-4038);
}
/* Page separator:16 */
.MES16 {
& > hr {border-width: 1px;}
& > hr {border-color: var(--clr-4039) transparent transparent transparent;}
& > hr {border-top-style: dashed;}
& > hr {border-right-style: dashed;}
& > hr {border-bottom-style: dashed;}
& > hr {border-left-style: dashed;}
 }
/* parallax 400px:17 */
.MES17 {
background-color: var(--clr-4041);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://blindrepair.com.au/img/54/17');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES17 {
background-color: var(--clr-4041);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://blindrepair.com.au/img/54/17');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: #ffffff;
 }
 }
cite.MEC17{
color: #ffffff;
}
/* parallax:18 */
.MES18 {
background-color: var(--clr-4041);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://blindrepair.com.au/img/53/16');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES18 {
background-color: var(--clr-4041);
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://blindrepair.com.au/img/53/16');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 150%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: #ffffff;
 }
 }
cite.MEC18{
color: #ffffff;
}
/* Footer banner:19 */
.MES19 {
background-color: var(--clr-4041);
color: var(--clr-4037);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.6px;
};
padding: 15px;

 }
.MES19 {
background-color: var(--clr-4041);
color: var(--clr-4037);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.6px;
};
padding: 15px;

 }
cite.MEC19{
color: var(--clr-4037);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.6px;
};
}
/* home green block:20 */
.MES20 {
background-color: var(--clr-4039);
color: #ffffff;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
.MES20 {
background-color: var(--clr-4039);
color: #ffffff;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 10px;

@media #{$large-up} {
padding: 15px 20px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
h1.MEC20 { font-size: 15px; }
h1.MEC20 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC20 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC20 { font-size: 13.2px; }
h2.MEC20 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC20 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC20 { font-size: 10.8px; }
h3.MEC20 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC20 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC20 { font-size: 10.8px; }
h4.MEC20 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC20 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC20 { font-size: 10.2px; }
h5.MEC20 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC20 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC20 { font-size: 9.6px; }
h6.MEC20 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC20 { @media #{$large-up} { font-size: 18px; }; }
 }
.MEC20 li {color: #ffffff;}
cite.MEC20{
color: #ffffff;
}
/* Colour:21 */
.MES21 {
background-color: var(--clr-4043);
color: #ffffff;
font-size: 18px;
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Copyright:22 */
.MES22 {
color: #ffffff;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES22 {
color: #ffffff;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC22{
color: #ffffff;
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
